import { AvatarBlurhash, AvatarSkeleton } from '../AvatarBlurhash'
import { type AvatarProps, type EntityAvatarProps } from './types'
import { getInitialism } from '@front/model'
import { Group, Skeleton, Text } from '@mantine/core'
import { type ReactElement } from 'react'

type Props = AvatarProps & EntityAvatarProps

export const PrefetchedEntityInfo = ({
  avatarBlurhash,
  avatarUrl,
  name,
  initials,
  size = 'sm',
  avatarOnly,
  companyName,
}: Props): ReactElement => {
  const labelString = companyName ? `${name} (${companyName})` : name
  return (
    <Group wrap='nowrap'>
      <AvatarBlurhash
        avatarBlurhash={avatarBlurhash}
        avatarUrl={avatarUrl}
        initials={initials ?? getInitialism(name)}
        size={size}
      />
      {!avatarOnly && (
        <Text lineClamp={1} size={size}>
          {labelString}
        </Text>
      )}
    </Group>
  )
}

export const LoadingEntityInfo = ({ size, avatarOnly }: AvatarProps): ReactElement => {
  return (
    <Group wrap='nowrap'>
      <AvatarSkeleton size={size} />
      {!avatarOnly && <Skeleton h={20} w={100} />}
    </Group>
  )
}
